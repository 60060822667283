import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import { Layout } from "../components/Layout";
import { Section, LoginForm } from "../style/globalStyles";
import { userLogin } from "../state";
export default function Login() {
  const loginData = useSelector((state) => state.auth.user.portalUserId);
  // console.log(loginData)

  const loginBool = Boolean(loginData);
  useEffect(() => {
    if (loginBool) {
      navigate("/dashboardadmin");
    }
  });

  const [state, setState] = useState({});
  const [enable, setEnable] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (state.emailId && state.password) {
      setEnable(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("login state", state)
    dispatch(userLogin(state));
  };
  return (
    <Layout>
      <Section
        style={{
          flexWrap: "nowrap",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(70vh - 5rem)",
        }}
      >
        <LoginForm onSubmit={handleSubmit}>
          <h1>Zyppys Admin Login</h1>
          <br />
          <br />
          <label htmlFor="emailId">
            E-Mail
            {/* <p>{doctorData.firstName}</p> */}
            <input
              id="emailId"
              name="emailId"
              type="email"
              placeholder={"Enter e-mail address"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="password">
            Password
            {/* <p>{doctorData.firstName}</p> */}
            <input
              id="password"
              name="password"
              type="password"
              placeholder={"Enter password"}
              onChange={handleChange}
            />
          </label>
          <button
            type="submit"
            aria-disabled={enable ? true : false}
            style={{
              backgroundColor: state ? "green" : "lightgray",
              // cursor: enable ? "pointer" : "default",
            }}
          >
            Login
          </button>
        </LoginForm>
      </Section>
    </Layout>
  );
}
